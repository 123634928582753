import ChannelActionList from './channel-action-list';
import DocumentActionList from './document-action-list';
import { UnitActionListProps } from './props';
import WhiteboardActions from './whiteboard-action-list';

export const UnitActionList = ({ unit, callback }: UnitActionListProps) => {
  if (unit.type === 'whiteboard') {
    return <WhiteboardActions unit={unit} callback={callback} />;
  }
  if (unit.type === 'document') {
    return <DocumentActionList unit={unit} callback={callback} />;
  }
  if (unit.type === 'channel') {
    return <ChannelActionList unit={unit} callback={callback} />;
  }

  return <></>;
};
