// @ts-nocheck
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { track } from '@amplitude/analytics-browser';

import { userApi } from '@app/redux/api/userApi';
import { selectCurrentUser } from '@app/redux/features/userSlice';

import Loading from '@shared/common/Loading';

const PrivateLayout = () => {
  const user = useSelector(selectCurrentUser);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    track('app_opened');
  }, []);

  const { isLoading, isFetching } = userApi.endpoints.getMe.useQuery(null, {
    skip: false,
  });

  const loading = isLoading || isFetching;

  const userData = userApi.endpoints.getMe.useQueryState(null, {
    selectFromResult: ({ data }) => data,
  });

  if (loading) {
    return location.pathname.includes('settings') ? <Outlet /> : <Loading />;
  }

  const goToLogin = () => {
    const loginUrl = `/login?returnURL=${location.pathname}${location.search}`;

    return <Navigate to={loginUrl} state={{ from: location }} />;
  };

  return userData?.id ? <Outlet /> : goToLogin();
};

export default PrivateLayout;
