import styles from './style.module.scss';
import cn from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as Plus } from '@images/plus.svg';
import { ReactComponent as Home } from '@images/home.svg';
import { ReactComponent as Document } from '@images/document.svg';
import { ReactComponent as Whiteboard } from '@images/whiteboard.svg';

import { Link, useNavigate } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';
import _ from 'lodash';
import useUnits from '@app/hooks/useUnits';
import { createDocument } from '@app/services/document.service';
import { UnitType } from '@entities/models/unit';
import { createWhiteboard } from '@app/services/whiteboard.service';
import { useClickOutside } from '@app/hooks/useClickOutside';

const SidebarTopActions = () => {
  const navigate = useNavigate();
  const newUnitClick = useClickOutside(false);
  const { units, addUnit } = useUnits();

  const createUnit = async (type: UnitType) => {
    const channel = units
      .filter((unit) => unit.type === 'channel')
      .find((unit) => unit.isDefault);
    if (channel) {
      const id = uuidv4();
      const newUnit = await getCreateQuery(type, channel.id, id);
      if (newUnit) {
        addUnit(newUnit);
        _.delay(() => {
          newUnitClick.setIsVisible(false);
          navigate(`/workspace/${newUnit.id}`);
        }, 100);
      }
    }
  };

  const getCreateQuery = (type: UnitType, channelId: string, id: string) => {
    switch (type) {
      case 'document':
        return createDocument(channelId, id);
      case 'whiteboard':
        return createWhiteboard(channelId, id);
      default:
        return createDocument(channelId, id);
    }
  };

  const handleOpenNewMenu = () => {
    newUnitClick.setIsVisible(!newUnitClick.isVisible);
  };

  const goToHome = () => {
    track('home_opened', { source: 'sidebar' });
  };

  return (
    <div className={styles.sidebarTopActions}>
      <div
        ref={newUnitClick.ref}
        onClick={handleOpenNewMenu}
        className={cn(
          {
            [styles.sidebarTopActionsButtonDarkActive]: newUnitClick.isVisible,
          },
          styles.sidebarTopActionsButton,
          styles.sidebarTopActionsButtonDark
        )}
      >
        <Plus /> New
        {newUnitClick.isVisible && (
          <div className={styles.sidebarTopActionsButtonNewMenu}>
            <div
              className={styles.sidebarTopActionsButtonNewMenuItem}
              onClick={() => createUnit('document')}
            >
              <Document />
              New doc
            </div>
            <div
              className={styles.sidebarTopActionsButtonNewMenuItem}
              onClick={() => createUnit('whiteboard')}
            >
              <Whiteboard />
              New whiteboard
            </div>
          </div>
        )}
      </div>
      <Link
        to='/home'
        onClick={goToHome}
        className={styles.sidebarTopActionsButton}
      >
        <Home /> Home
      </Link>
    </div>
  );
};

export default SidebarTopActions;
