import styles from './style.module.scss';
import cn from 'classnames';

import { ReactComponent as Template } from '@images/template.svg';
import { ReactComponent as Help } from '@images/help.svg';
import { ReactComponent as AddUser } from '@images/userAdd.svg';

import MemberInvite from '@pages/Settings/Members/MemberInvite';
import useUser from '@app/hooks/useUser';
import useWorkspace from '@app/hooks/useWorkspace';
import { useState } from 'react';
import { track } from '@amplitude/analytics-browser';

const SidebarBottomActions = () => {
  const user = useUser();
  const { workspace } = useWorkspace();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <div className={styles.sidebarBottomActions}>
      <div
        onClick={() => {
          track('help_opened');
          Object(window).Intercom('show');
        }}
        className={styles.sidebarBottomActionsButton}
      >
        <Help /> Help
      </div>
      <div className={cn(styles.sidebarBottomActionsButton, {[styles.sidebarBottomActionsButtonDisabled]: true})}>
        <Template /> Templates
      </div>
      {workspace?.owner.id === user?.id && (
        <div
          onClick={() => setModalIsOpen(true)}
          className={styles.sidebarBottomActionsButtonInvite}
        >
          <AddUser /> Invite people
        </div>
      )}
      <MemberInvite modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
    </div>
  );
};

export default SidebarBottomActions;
