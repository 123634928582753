import { IShareButton } from './ShareButton.types';
import { ShareDoc } from '@widgets/components/ShareDoc';
import { useMemo, useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import { useDispatch } from 'react-redux';
import { updateShareModalState } from '@app/redux/features/modalsSlice';

import cssStyles from './style.module.scss';
import { ReactComponent as ShareIconComponent } from '@images/share.svg';

const ShareButton = ({ unit, withIcon, callback }: IShareButton) => {
  const dispatch = useDispatch();

  const title = useMemo(() => {
    if (unit.type === 'document') {
      return 'Share doc';
    }
    if (unit.type === 'channel') {
      return 'Share channel';
    }

    if (unit.type === 'whiteboard') {
      return 'Share whiteboard';
    }

    return 'Share doc';
  }, [unit.type]);

  const openShareModal = () => {
    track('channel_settings_share_action');
    dispatch(
      updateShareModalState({
        isOpen: true,
        title: title,
        unitId: unit.id,
      })
    );
    callback && callback();
  };

  return (
    <>
      {withIcon ? (
        <div className={cssStyles.shareWithIcon} onClick={openShareModal}>
          <ShareIconComponent className={cssStyles.shareIcon}/>
          Share
        </div>
      ) : (
        <div className={cssStyles.share} onClick={openShareModal}>Share</div>
      )}
    </>
  );
};

export default ShareButton;
