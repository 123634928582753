import styles from './style.module.scss';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';

import { ReactComponent as Settings } from '@images/settings.svg';
import { ReactComponent as Arrow } from '@images/arrow.svg';
import { ReactComponent as Help } from '@images/help.svg';
import { ReactComponent as Trash } from '@images/trash.svg';
import { ReactComponent as Logo } from '@images/logo.svg';
import { ReactComponent as Check } from '@images/checkOriginal.svg';
import { ReactComponent as LogOut } from '@images/logout.svg';

import useWorkspace from '@app/hooks/useWorkspace';
import { track } from '@amplitude/analytics-browser';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '@app/redux/features/userSlice';
import { Workspace } from '@entities/models/workspace';

const SettingsMenu = ({ closeCallback }: { closeCallback: () => void }) => {
  const { workspace, workspaces, switchWorkspace } = useWorkspace();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToSettings = () => {
    track('settings_opened');
    closeCallback();
  };

  const handleHelp = () => {
    track('help_opened', { source: 'logo' });
    Object(window).Intercom('show');
    closeCallback();
  };

  const goToTrash = () => {
    track('trash_opened');

    closeCallback();
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
    closeCallback();
  };

  const hadleSwitchWorkspace = (workspace: Workspace) => {
    closeCallback();
    switchWorkspace(workspace)
    navigate('/home');
  }

  return workspace ? (
    <div className={styles.settingsMenu}>
      <Link
        to='/settings/account'
        className={styles.settingsMenuItem}
        onClick={goToSettings}
      >
        <Settings />
        Settings
        <Arrow className={styles.secondaryInlineIcon} />
      </Link>
      <div className={styles.settingsMenuItem} onClick={handleHelp}>
        <Help />
        Help
      </div>
      <Link to='/trash' className={styles.settingsMenuItem} onClick={goToTrash}>
        <Trash />
        Trash
      </Link>
      <div className={styles.settingsMenuDivider}></div>
      {workspaces.length > 1 && (
        <div className={styles.settingsMenuWorkspaceListTitle}>
          Switch workspace
        </div>
      )}
      {workspaces.map((_workspace) => (
        <div
          key={uuidv4()}
          className={cn(
            styles.settingsMenuItem,
            styles.settingsMenuItem__black
          )}
          onClick={() => hadleSwitchWorkspace(_workspace)}
        >
          <Logo />
          {_workspace.name}
          {workspace.id === _workspace.id && (
            <Check className={styles.secondaryInlineIcon} />
          )}
        </div>
      ))}
      <div className={styles.settingsMenuDivider}></div>
      <div className={styles.settingsMenuItem} onClick={handleLogout}>
        <LogOut /> Log out
      </div>
    </div>
  ) : null;
};

export default SettingsMenu;
