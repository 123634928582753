import { AccessTypes, InviteeType } from "./types";

type AccessLabelsType = Record<AccessTypes, string>

export const AccessLabels: AccessLabelsType = {
  view: 'Can view',
  comment: 'Can comment',
  edit: 'Can edit',
  full_access: 'Full access',
  no: 'No access',
};

export const ACCESS_TYPES = Object.keys(AccessLabels) as AccessTypes[];

export const INVITEE_TYPES: InviteeType[] = ['member', 'guest'];

type InviteeTypeLabels = Record<InviteeType, string>;

export const INVITEE_TYPES_LABELS: InviteeTypeLabels = {
  member: 'Member',
  guest: 'Guest',
};
