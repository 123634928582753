import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import MemberInvite from '@pages/Settings/Members/MemberInvite';
import cn from 'classnames';
import * as _ from 'lodash';
import Sidebar from '@widgets/sidebar';

const HomeLayout = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <div className={cn('h-screen w-screen flex')} contentEditable={false}>
      <Sidebar />
      <div className='home-layout '>
        <Outlet />
      </div>
      <MemberInvite modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
    </div>
  );
};

export default HomeLayout;
