import styles from './style.module.scss';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';

import { ReactComponent as Plus } from '@images/plus.svg';
import { ReactComponent as Everything } from '@images/everything.svg';
import { ReactComponent as ArrowDown } from '@images/arrowDownSmall.svg';
import { ReactComponent as DocumentIcon } from '@images/document.svg';
import { ReactComponent as WhiteboardIcon } from '@images/whiteboard.svg';
import { ReactComponent as Document } from '@images/document.svg';
import { ReactComponent as Whiteboard } from '@images/whiteboard.svg';

import TreeView, { INode, flattenTree } from 'react-accessible-treeview';
import useUnits from '@app/hooks/useUnits';
import { useEffect, useMemo, useState } from 'react';
import { Unit, UnitType } from '@entities/models/unit';
import { IFlatMetadata } from 'react-accessible-treeview/dist/TreeView/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { createDocument } from '@app/services/document.service';
import _ from 'lodash';
import MoreButton from '@shared/uikit/more-button';
import ComingSoon from '@widgets/components/ComingSoon';
import { track } from '@amplitude/analytics-browser';
import CreateChannel from '@widgets/components/Modals/CreateChannel/CreateChannel';
import { UnitActionList } from '@widgets/unit-action-lists';
import { createWhiteboard } from '@app/services/whiteboard.service';
import { useClickOutside } from '@app/hooks/useClickOutside';

type TreeNode = {
  id: string;
  name: string;
  children: TreeNode[];
};

const SidebarUnitsPanel = () => {
  const { units, addUnit } = useUnits();
  const { documentId } = useParams();
  const navigate = useNavigate();
  const [isCreateChannelModalOpen, setCreateChannelModalState] =
    useState<boolean>(false);
  const newUnitClick = useClickOutside(false);

  const getChildrens = (parentUnitId: string | null): TreeNode[] => {
    const childs = parentUnitId
      ? units.filter(
          (unit) => unit.parentUnit && unit.parentUnit.id === parentUnitId
        )
      : units.filter((unit) => unit.parentUnit === null);
    return childs.map((unit: Unit) => {
      return {
        id: unit.id,
        name: unit.name,
        children: getChildrens(unit.id),
      };
    });
  };

  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [showAddNewUnit, setShowNewUnit] = useState(false);

  const onLoadData = ({ data }: { data: INode<IFlatMetadata>[] }) => {
    return new Promise<void>((resolve) => {
      if (documentId && data.find((x) => x.id === documentId)) {
        setSelectedIds([documentId]);
        const _expandedIds: string[] = [];
        let unit = units.find((unit) => unit.id === documentId);
        if (unit) {
          _expandedIds.push(documentId);
          while (unit && unit.parentUnit) {
            if (unit && unit.parentUnit && unit.parentUnit.id) {
              const parentId: string = unit.parentUnit.id;
              _expandedIds.push(parentId);
              unit = units.find((x) => x.id === parentId);
            }
          }
          setExpandedIds(_expandedIds);
        }
      } else {
        setSelectedIds([]);
      }
      resolve();
    });
  };

  const { data } = useMemo(() => {
    const data = flattenTree({
      name: '',
      children: getChildrens(null),
    });

    onLoadData({ data });

    return { data };
  }, [units, documentId]);

  const getIcon = (element: INode<IFlatMetadata>) => {
    const unit = units.find((unit) => unit.id === element.id);
    const type = unit?.type ?? 'default';
    const color = unit?.color ?? '#719eff88';
    switch (type) {
      case 'document':
        return <DocumentIcon />;
      case 'channel':
        return (
          <ColoredIcon letter={element.name[0].toUpperCase()} color={color} />
        );
      case 'whiteboard':
        return <WhiteboardIcon />;
      default:
        <ColoredIcon letter={element.name[0].toUpperCase()} color={color} />;
    }
  };

  const handleCreateSubDocument = async (event: any, unit: Unit) => {
    event.stopPropagation();
    const newDocumentId = uuidv4();
    const newUnit = await createDocument(unit.id, newDocumentId);
    if (newUnit) {
      addUnit(newUnit);
      openUnit(newUnit.id);
    }
  };

  const handleCreateChannel = () => {
    track('create_channel_popup_opened');
    setCreateChannelModalState(true);
  };

  const openUnit = (id: string) => {
    navigate(`/workspace/${id}`);
  };

  const expand = (element: INode<IFlatMetadata>, isExpanded: boolean) => {
    const _expandedIds: string[] = [];
    let unit = units.find((unit) => unit.id === documentId);
    if (unit) {
      if (!isExpanded) {
        _expandedIds.push(element.id.toString());
      }
      while (unit && unit.parentUnit) {
        if (unit && unit.parentUnit && unit.parentUnit.id) {
          const parentId: string = unit.parentUnit.id;
          _expandedIds.push(parentId);
          unit = units.find((x) => x.id === parentId);
        }
      }
      setExpandedIds(_expandedIds);
    }
  };

  const createUnit = async (type: UnitType) => {
    const channel = units
      .filter((unit) => unit.type === 'channel')
      .find((unit) => unit.isDefault);
    if (channel) {
      const id = uuidv4();
      const newUnit = await getCreateQuery(type, channel.id, id);
      if (newUnit) {
        addUnit(newUnit);
        _.delay(() => {
          setShowNewUnit(false);
          navigate(`/workspace/${newUnit.id}`);
        }, 100);
      }
    }
  };

  const getCreateQuery = (type: UnitType, channelId: string, id: string) => {
    switch (type) {
      case 'document':
        return createDocument(channelId, id);
      case 'whiteboard':
        return createWhiteboard(channelId, id);
      default:
        return createDocument(channelId, id);
    }
  };

  const [activeEl, setActiveEl] = useState<any>('');

  const isActiveElement = (element: any) => {
    return element.id == activeEl;
  };

  useEffect(() => {
    !newUnitClick.isVisible && setActiveEl('');
  }, [newUnitClick.isVisible]);

  const getActions = (element: INode<IFlatMetadata>) => {
    const unit = units.find((unit) => unit.id === element.id);

    if (unit) {
      if (unit.isDefault) {
        return (
          <div
            ref={newUnitClick.ref}
            onClick={() => newUnitClick.setIsVisible(!newUnitClick.isVisible)}
            style={{ position: 'relative' }}
            className={cn([styles.sidebarTopActionsButtonNewMenuWrapper])}
          >
            <div
              className={cn([
                styles.sidebarUnitsPanelTreeNodeActionsAddSubDocument,
              ])}
              onClick={(e) => {
                newUnitClick.setIsVisible(true);
                setActiveEl(element.id);
              }}
            >
              <Plus />
            </div>
            {newUnitClick.isVisible && (
              <div className={styles.sidebarTopActionsButtonNewMenu}>
                <div
                  className={styles.sidebarTopActionsButtonNewMenuItem}
                  onClick={() => createUnit('document')}
                >
                  <Document />
                  New doc
                </div>
                <div
                  className={styles.sidebarTopActionsButtonNewMenuItem}
                  onClick={() => createUnit('whiteboard')}
                >
                  <Whiteboard />
                  New whiteboard
                </div>
              </div>
            )}
          </div>
        );
      } else {
        return (
          <>
            <MoreButton
              isCompact={true}
              position='right'
              children={<UnitActionList unit={unit} />}
              onOpenCallback={() => {
                track(`${unit.type}_advanced_menu_opened`);
              }}
            />
            <div
              className={styles.sidebarUnitsPanelTreeNodeActionsAddSubDocument}
              onClick={(e) => handleCreateSubDocument(e, unit)}
            >
              <Plus />
            </div>
          </>
        );
      }
    }

    return <></>;
  };

  return (
    <div className={styles.sidebarUnitsPanel} contentEditable={false}>
      <div className={styles.sidebarUnitsPanelRow}>
        <span className={styles.sidebarUnitsPanelChannelTitle}>Channels</span>
        <div
          className={styles.sidebarUnitsPanelChannelIcon}
          onClick={handleCreateChannel}
        >
          <Plus />
        </div>
        <CreateChannel
          handleClose={() => {
            track('channel_create_closed');
            setCreateChannelModalState(false);
          }}
          isOpen={isCreateChannelModalOpen}
        />
      </div>
      <div className={styles.sidebarUnitsPanelRowDisable}>
        <Everything />
        Everything
        <ComingSoon right={40} />
      </div>
      <TreeView
        data={data}
        className={styles.sidebarUnitsPanelTree}
        selectedIds={selectedIds}
        expandedIds={expandedIds}
        onNodeSelect={(props) => {}}
        nodeRenderer={({
          element,
          getNodeProps,
          level,
          isSelected,
          isExpanded,
          isBranch,
        }) => (
          <div
            {...getNodeProps()}
            className={cn(styles.sidebarUnitsPanelTreeNode, {
              [styles.sidebarUnitsPanelTreeNodeSelected]:
                isSelected || element.id === documentId,
            })}
            style={{
              marginLeft: 24 * (level - 1),
              width: 240 - 24 * (level - 1),
              maxWidth: 240 - 24 * (level - 1),
              paddingLeft: isBranch ? 2 : 12,
            }}
            onClick={(e) => {
              e.preventDefault();
              openUnit(element.id.toString());
            }}
          >
            <div className='flex items-center w-[calc(100%-40px)]'>
              {isBranch && (
                <div
                  className='w-3 h-3 flex items-center justify-center rounded-md hover:bg-[#e2e2e2]'
                  onClick={(e) => {
                    e.preventDefault();
                    e.preventDefault();
                    expand(element, isExpanded);
                  }}
                >
                  <ArrowDown
                    className={cn({
                      [styles.sidebarUnitsPanelTreeNodeArrowExpanded]:
                        isExpanded,
                      [styles.sidebarUnitsPanelTreeNodeArrowNotExpanded]:
                        !isExpanded,
                    })}
                  />
                </div>
              )}
              {getIcon(element)}
              <span className={cn(styles.sidebarUnitsPanelTreeNodeTitle)}>
                {element.name}
              </span>
            </div>
            <div
              className={cn([
                styles.sidebarUnitsPanelTreeNodeActions,
                isActiveElement(element)
                  ? styles.showSubMenu
                  : styles.hideSubMenu,
              ])}
            >
              {getActions(element)}
            </div>
          </div>
        )}
      />
      {/* {newUnitClick.isVisible && (
        <div className={styles.sidebarTopActionsButtonNewMenu}>
          <div
            className={styles.sidebarTopActionsButtonNewMenuItem}
            onClick={() => createUnit('document')}
          >
            <Document />
            New doc
          </div>
          <div
            className={styles.sidebarTopActionsButtonNewMenuItem}
            onClick={() => createUnit('whiteboard')}
          >
            <Whiteboard />
            New whiteboard
          </div>
        </div>
      )} */}
    </div>
  );
};

export default SidebarUnitsPanel;

const ColoredIcon = ({ letter, color }: { letter: string; color: string }) => {
  return (
    <div
      className={styles.sidebarUnitsPanelColoredIcon}
      style={{
        backgroundColor: color,
        color:
          parseInt(color.replace('#', ''), 16) > 0xffffff / 2 ? '#000' : '#fff',
      }}
    >
      <span>{letter}</span>
    </div>
  );
};
