import WhiteboardSettingsButton from '@widgets/components/Actions/WhiteboardSettingsButton';
import { UnitActionListProps } from '../props';
import DuplicateButton from '@widgets/components/Actions/DuplicateButton/DuplicateButton.component';
import CopyLinkButton from '@widgets/components/Actions/CopyLinkButton/CopyLinkButton.component';
import ShareButton from '@widgets/components/Actions/ShareButton/ShareButton.component';
import DeleteButton from '@widgets/components/Actions/DeleteButton/DeleteButton.component';

const WhiteboardActions = ({ unit, callback }: UnitActionListProps) => {
  return (
    <>
      <WhiteboardSettingsButton unit={unit} callback={callback} />
      <DuplicateButton unit={unit} callback={callback} />
      <CopyLinkButton unit={unit} isCompact={false} callback={callback} />
      <ShareButton unit={unit} withIcon={true} callback={callback} />
      <DeleteButton unit={unit} callback={callback} />
    </>
  );
};

export default WhiteboardActions;
