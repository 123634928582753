import Modal from '@shared/common/Modal';
import Button from '@shared/common/Button';
import useWorkspace from '@app/hooks/useWorkspace';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useDeleteWorkspaceMutation } from '@app/redux/api/workspaceApi';

type DeleteOrLeaveConfirmationModalProps = {
  close: () => void;
  isOpen: boolean;
  isOwner: boolean;
};

export const DeleteOrLeaveConfirmationModal: React.FC<
  DeleteOrLeaveConfirmationModalProps
> = ({ close, isOpen, isOwner }) => {
  const { workspace } = useWorkspace();

  const [deleteWorkspace, { isLoading: isDelete }] =
    useDeleteWorkspaceMutation();

  const title = useMemo(() => {
    if (isOwner) {
      return 'Delete workspace';
    }

    return 'Leave workspace';
  }, [isOwner]);

  const confirmBtnLabel = useMemo(() => {
    if (isOwner) {
      return 'Delete workspace';
    }

    return 'Leave workspace';
  }, [isOwner]);

  const handleDeleteWorkspace = useCallback(() => {
    if (!workspace) {
      return;
    }

    deleteWorkspace({ id: workspace.id || '' })
      .unwrap()
      .then(() => close());
  }, [deleteWorkspace, workspace]);

  const handleLeaveWorkspace = useCallback(() => {
    if (!workspace) {
      return;
    }

    // ToDo add leave endpoint call
  }, [deleteWorkspace, workspace]);

  const onConfirmBtnClick = useCallback(() => {
    if (isOwner) {
      handleDeleteWorkspace();
      return;
    }

    handleLeaveWorkspace();
  }, [isOwner, handleDeleteWorkspace]);

  const [modalClass, setModalClass] = useState<string>('invisible');

  useLayoutEffect(() => {
    if (!isOpen) {
      return setModalClass('invisible');
    }
    setTimeout(() => {
      setModalClass('');
    }, 0);
  }, [isOpen]);

  if (!workspace) return null;

  return (
    <Modal
      modalIsOpen={isOpen}
      closeModal={close}
      closeButton={false}
      userClassName={modalClass}
    >
      <div
        className='text-20-16 font-medium text-text90'
        contentEditable='false'
      >
        {title}
      </div>
      <div className='w-96 mt-5 text-base text-text70'>
        This action cannot be undone. This will permanently delete your entire
        account. All private workspaces will be deleted, and you will be removed
        from all shared workspaces.
      </div>
      <div className='mt-4 font-medium text-16-28 text-errorText'>
        {workspace.name}
      </div>
      <div className='flex mt-10 items-center'>
        <Button
          label='Cancel'
          styleType='small-gray'
          onClick={close}
          className='w-full mr-3'
        />
        <Button
          label={confirmBtnLabel}
          className='!text-errorText w-full'
          styleType='small-gray'
          onClick={onConfirmBtnClick}
          isLoading={isDelete}
          disabled={isDelete}
        />
      </div>
    </Modal>
  );
};
