import { Workspace } from '@entities/models/workspace';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentUser,
  setWorkspace as setWorkspaceContext,
  setWorkspaces as setWorkspacesContext,
} from '../redux/features/userSlice';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getWorkspaces } from '../services/workspace.service';
import _ from 'lodash';

const useWorkspace = () => {
  const userState = useSelector(selectCurrentUser);
  const [workspace, setWorkspace] = useState<Workspace | null>(null);
  const dispatch = useDispatch();

  const workspacesLoaded = useRef(userState.workspaces.length > 0);
  const areWorkspacesLoading = useRef(false);

  const fetchWorkspaces = useCallback(async () => {
    try {
      areWorkspacesLoading.current = true;
      const fetchedWorkspaces = await getWorkspaces();

      dispatch(setWorkspacesContext(fetchedWorkspaces));
      workspacesLoaded.current = true;

      return fetchedWorkspaces;
    } catch (error) {
      console.log(`Error during loading workspaces: ${error}`);
    } finally {
      areWorkspacesLoading.current = false;
    }
  }, []);

  const initWorkSpaces = useCallback(async () => {
    if (!userState || !userState.isLoggedIn) {
      setWorkspace(null);
      workspacesLoaded.current = false;
      return;
    }

    if (!workspacesLoaded.current) {
      if (!areWorkspacesLoading.current) {
        fetchWorkspaces();
      }
    }

    if (!userState.workspace) {
      const workspaceId = localStorage.getItem('workspaceId');
      if (workspaceId) {
        const workspace = userState.workspaces.find(
          (workspace) => workspace.id === workspaceId
        );
        if (workspace) {
          dispatch(setWorkspaceContext(workspace));
          setWorkspace(workspace);
        } else {
          dispatch(setWorkspaceContext(userState.workspaces[0]));
          setWorkspace(userState.workspaces[0]);
        }
      } else {
        dispatch(setWorkspaceContext(userState.workspaces[0]));
        setWorkspace(userState.workspaces[0]);
      }
    } else {
      setWorkspace(userState.workspace);
    }
  }, [userState, fetchWorkspaces]);

  useEffect(() => {
    initWorkSpaces();
  }, [initWorkSpaces]);

  const switchWorkspace = (ws: Workspace): void => {
    const _workspace = userState.workspaces.find(
      (item) => item.id === ws.id
    );
    if (_workspace) {
      dispatch(setWorkspaceContext(ws));
      setWorkspace(ws);
    } else {
      dispatch(setWorkspaceContext(userState.workspaces[0]));
      setWorkspace(userState.workspaces[0]);
    }
  };

  const fetchAndSetWorkspace = useCallback(async (workspaceId: string) => {
    workspacesLoaded.current = false;

    const workspaces = await fetchWorkspaces() || [];
    const ws = workspaces.find((item) => item.id === workspaceId);

    if (ws) {
      dispatch(setWorkspaceContext(ws));
    }
  }, [fetchWorkspaces]);

  return {
    workspace,
    workspaces: userState.workspaces,
    switchWorkspace,
    fetchAndSetWorkspace,
  };
};

export default useWorkspace;
