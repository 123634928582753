import { useEffect } from 'react';
import { trashPageResources } from './TrashPage.resources';
import { ITrashPage } from './TrashPage.types';
import { track } from '@amplitude/analytics-browser';
import { Unit } from '@entities/models/unit';
import { v4 as uuidv4 } from 'uuid';
import TrashItem from './TrashItem/TrashItem.component';
import { useDispatch } from 'react-redux';
import { setDeletedUnits } from '@app/redux/features/unitsSlice';
import { getRemovedItems } from './TrashPage.service';
import cn from 'classnames';

import cssStyles from './style.module.scss';
import { useLocalStorage } from 'usehooks-ts';
import useWorkspace from '@app/hooks/useWorkspace';
import useUnits from '@app/hooks/useUnits';

const TrashPage = ({}: ITrashPage) => {
  const [isHide] = useLocalStorage('sidebarHidden', false);
  const { workspace } = useWorkspace();
  const { breadcrumbs, title } = trashPageResources['en'];
  const { deletedUnits } = useUnits();
  const dispatch = useDispatch();

  useEffect(() => {
    track('trash_page_opened');
    if (workspace) {
      getRemovedItems(workspace.id).then((units) => {
        if (units != deletedUnits) {
          dispatch(setDeletedUnits(units));
        }
      });
    }
  }, []);

  return (
    <div className='workspace-details'>
      <div className='head-panel'>
        <div
          className={cn('bread-crumbs', {
            'bread-crumbs__withShowSidebarButton': isHide,
          })}
        >
          {breadcrumbs}
        </div>
      </div>
      <div className={cn('workspace', cssStyles.workspace)}>
        <div className={cn('title', cssStyles.title)}>{title}</div>
        <div className={cssStyles.trashItemsList}>
          {deletedUnits.map((item: Unit) => (
            <TrashItem
              key={uuidv4()}
              item={item}
              workspaceId={workspace?.id || ''}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TrashPage;
