import useUser from '@app/hooks/useUser';
import useWorkspace from '@app/hooks/useWorkspace';
import Button from '@shared/common/Button';
import { useCallback, useMemo, useState } from 'react';
import { DeleteOrLeaveConfirmationModal } from './ConfirmationModal';

export const DeleteOrLeave = () => {
  const user = useUser();
  const { workspace } = useWorkspace();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = useCallback(() => {
    setModalIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const isOwner = useMemo(() => {
    if (!workspace || !user) {
      return false;
    }

    if (user.id === workspace.owner.id) {
      return true;
    }

    return false;
  }, [user, workspace]);

  if (!workspace) return null;
  if (!user) return null;

  return (
    <>
      {isOwner && (
        <>
          <div className='font-medium text-lg3 text-text90 mt-7.5'>
            Delete workspace
          </div>
          <div className='font-normal leading-[18px] text-sm text-text50 mt-4'>
            This action cannot be undone. This will permanently delete the
            workspace, including all
            <br />
            pages and files. Please type the name of the workspace to confirm.
          </div>
        </>
      )}
      <div className='mt-5 flex items-center'>
        {!isOwner && (
          <Button
            label='Leave workspace'
            className='mr-3'
            styleType='small-gray'
            onClick={openModal}
          />
        )}
        {isOwner && (
          <Button
            label='Delete workspace'
            className='!text-errorText'
            styleType='small-gray'
            onClick={openModal}
          />
        )}
      </div>
      <DeleteOrLeaveConfirmationModal
        isOwner={isOwner}
        isOpen={modalIsOpen}
        close={closeModal}
      />
    </>
  );
};
