import { createApi } from '@reduxjs/toolkit/query/react';
import { Workspace } from '@entities/models/workspace';

import { setWorkspace } from '@app/redux/features/userSlice';
import customFetchBase from './customFetchBase';

export const workspaceApi = createApi({
  reducerPath: 'workspaceApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    changeWorkspace: builder.mutation<Workspace, { id: string; name: string }>(
      {
        query: ({ id, ...data }) => ({
          url: 'workspace/' + id,
          method: 'PATCH',
          body: data,
        }),
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;

            if (data.id) {
              dispatch(setWorkspace(data));
            }
          } catch (error) {}
        },
      }
    ),
    deleteWorkspace: builder.mutation<null, { id: string }>({
      query({ id }) {
        return {
          url: 'workspace/' + id,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const { useChangeWorkspaceMutation, useDeleteWorkspaceMutation } =
  workspaceApi;
